/**
 *格式化时间
 *yyyy-MM-dd hh:mm:ss
 */

// 返回时间格式为: yy-mm-dd
export function formatDate(v) {
  const date = new Date(v)
  const Y = date.getFullYear() + '-'
  const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
  const D = date.getDate() + ' '
  const h = date.getHours() + ':'
  const m = date.getMinutes() + ':'
  const s = date.getSeconds()
  return Y + M + D + h + m + s
}

/*
 * 隐藏用户手机号中间四位
 */
export function hidePhone(phone) {
  return phone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')
}

// 数字转换
export function numberFilter(v, type) {
  if (!v) {
    return 0
  }
  if (v < 10000) {
    return v
  }
  const value = v / 10000
  if (type == 'w') {
    return Number.isInteger(value) ? value + 'w' : value.toFixed(1) + 'w'
  }
  return Number.isInteger(value) ? value + 'w' : value.toFixed(1) + 'w'
}
// 金额单位转换  分转元
export function changeMoneyYuan(v) {
  if (v) {
    return (v / 100).toFixed(2)
  } else {
    return 0
  }
}

// 金额单位转换  分转元(保留1位小数)
export function changeGold(v) {
  if (v) {
    return (v / 100).toFixed(0)
  } else {
    return 0
  }
}
export function changeMoneyFen(val) {
  return parseFloat((val * 100).toFixed(10))
}

// 时间差转换
export function timeDiff(v) {
  let result = ''
  const date = Date.parse(v)
  const minute = 1000 * 60
  const hour = minute * 60
  const day = hour * 24
  const month = day * 30
  const now = new Date().getTime()
  const diffValue = now - date
  if (diffValue < 0) {
    return
  }
  const monthC = diffValue / month
  const weekC = diffValue / (7 * day)
  const dayC = diffValue / day
  const hourC = diffValue / hour
  const minC = diffValue / minute
  if (monthC >= 1) {
    result = '' + parseInt(monthC) + '月前'
  } else if (weekC >= 1) {
    result = '' + parseInt(weekC) + '周前'
  } else if (dayC >= 1) {
    result = '' + parseInt(dayC) + '天前'
  } else if (hourC >= 1) {
    result = '' + parseInt(hourC) + '小时前'
  } else if (minC >= 1) {
    result = '' + parseInt(minC) + '分钟前'
  } else {
    result = '剛剛'
  }
  return result
}
// 新的时间差转换
export function timeDiffNew(v) {
  let result = ''
  const date = Date.parse(v)
  const minute = 1000 * 60
  const hour = minute * 60
  const day = hour * 24
  const month = day * 30
  const now = new Date().getTime()
  const diffValue = now - date
  if (diffValue < 0) {
    return
  }
  const monthC = diffValue / month
  const weekC = diffValue / (7 * day)
  const dayC = diffValue / day
  const hourC = diffValue / hour
  const minC = diffValue / minute
  if (monthC >= 1) {
    result = dateFormat08(v)
  } else if (weekC >= 1) {
    result = dateFormat08(v)
  } else if (dayC >= 1) {
    result = dateFormat08(v)
  } else if (hourC >= 1) {
    result = '' + parseInt(hourC) + '小时前'
  } else if (minC >= 1) {
    result = '' + parseInt(minC) + '分钟前'
  } else {
    result = '剛剛'
  }
  return result
}
// 秒转化成 时分秒
export function secondToDate(v) {
  const date = new Date(v)
  var h = Math.floor((date / 3600) % 24)
  var m = Math.floor((date / 60) % 60)
  var s = Math.floor(date % 60)
  if (h < 10) {
    h = '0' + h
  }
  if (m < 10) {
    m = '0' + m
  }
  if (s < 10) {
    s = '0' + s
  }
  v = h + ':' + m + ':' + s
  return v
}
export function secondToMintue(v) {
  const date = new Date(v)
  var m = Math.floor((date / 60) % 60)
  var s = Math.floor(date % 60)

  if (m < 10) {
    m = '0' + m
  }
  if (s < 10) {
    s = '0' + s
  }
  v = m + ':' + s
  return v
}
// 返回时间格式为: yy-mm-dd
export function timeYmd(v) {
  const date = new Date(v)

  const Y = date.getFullYear() + '-'
  const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
  const D = date.getDate() + ' '

  return Y + M + D
}
// 罩杯转换
export function fillterCup(v) {
  const cupData = [
    { id: 1, name: 'B罩杯' },
    { id: 2, name: 'C罩杯' },
    { id: 3, name: 'D罩杯' },
    { id: 4, name: 'E罩杯' },
    { id: 5, name: 'F罩杯' },
    { id: 6, name: 'G罩杯' },
    { id: 7, name: 'H罩杯' },
    { id: 8, name: 'I罩杯' },
    { id: 9, name: 'J罩杯' },
    { id: 10, name: 'K罩杯' },
    { id: 11, name: 'L罩杯' }
  ]
  const res = cupData.filter(item => {
    return item.id === v
  })
  if (res && res[0]) {
    return res[0].name
  } else {
    return ''
  }
}
//8时区时间
export function dateFormat08(time) {
  var date = new Date(time)
  var year = date.getFullYear()
  var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
  var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  // 拼接
  return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes
}
